import Vue from 'vue';
import Vuex from 'vuex';
import loader from './common/loader'
import router from "../router";
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        isLoggedIn: !!localStorage.getItem('isLoggedIn'),
        userId: '',
        userDetail: localStorage.getItem('userDetail'),
        isDoctorLoggedIn: !!localStorage.getItem('isDoctorLoggedIn'),
        doctorUserId: localStorage.getItem('doctorUserId'),
        doctorUserDetail: localStorage.getItem('doctorUserDetail'),
        doctorProfileDetail: localStorage.getItem('doctorProfileDetail'),
        registrationStepperCount: localStorage.getItem('registrationStepperCount'),
        registrationFieldDetail: localStorage.getItem('registrationFieldDetail'),
        registrationSubmitFlag: localStorage.getItem('registrationSubmitFlag') ? localStorage.getItem('registrationSubmitFlag') : 0,
        displayProfileImage: localStorage.getItem('displayProfileImage') ? localStorage.getItem('displayProfileImage') : true,
        searchDoctorFilter: localStorage.getItem('searchDoctorFilter'),
        moduleName: localStorage.getItem('moduleName') ? localStorage.getItem('moduleName') : '',
        compareDoctorDetail: localStorage.getItem('compareDoctorDetail'),
        multipleBookingDetail: localStorage.getItem('multipleBookingDetail'),
        multipleBookingDoctorList: localStorage.getItem('multipleBookingDoctorList'),
        compareDoctorIds: localStorage.getItem('compareDoctorIds'),
        unreadNotification: localStorage.getItem('unreadNotification'),
        unreadDoctorNotification: localStorage.getItem('unreadDoctorNotification')
    },
    mutations: {
        setIsLoggedIn(state) {
            localStorage.setItem('isLoggedIn', true)
            state.isLoggedIn = true;
        },
        setIsDoctorLoggedIn(state) {
            localStorage.setItem('isDoctorLoggedIn', true)
            state.isDoctorLoggedIn = true;
        },
        // Set login data in state and local storage
        loginUser(state, data) {
            if (data.role_id == 2) {
                localStorage.setItem('doctorUserId', data.user_id)
                state.doctorUserId = data.user_id;
                localStorage.setItem('isDoctorLoggedIn', true)
                localStorage.setItem('doctorUserDetail', JSON.stringify(data))
                state.isDoctorLoggedIn = true;
                state.doctorUserDetail = JSON.stringify(data);
            } else {
                localStorage.setItem('userId', data.user_id)
                state.userId = data.user_id;
                localStorage.setItem('isLoggedIn', true)
                localStorage.setItem('userDetail', JSON.stringify(data))
                state.isLoggedIn = true;
                state.userDetail = JSON.stringify(data);
            }
        },
        // Remove login data in state and local storage
        logoutUser(state) {
            localStorage.removeItem('userId')
            localStorage.removeItem('isLoggedIn');
            localStorage.removeItem('userDetail');
            localStorage.removeItem('searchDoctorFilter');
            localStorage.removeItem('onlineApplicationData');
            localStorage.removeItem('multipleBookingDetail');
            localStorage.removeItem('multipleBookingDoctorList');
            localStorage.removeItem('prev_doctor_count');
            state.isLoggedIn = false;
            state.userId = null;
            state.userDetail = null;
            state.searchDoctorFilter = {};
        },
        logoutDoctorUser(state) {
            localStorage.removeItem('doctorUserId')
            localStorage.removeItem('isDoctorLoggedIn');
            localStorage.removeItem('doctorUserDetail');
            state.isDoctorLoggedIn = false;
            state.doctorUserId = null;
            state.doctorUserDetail = null;

        },
        updateProfileDetails(state, data) {
            let userDetail = JSON.parse(localStorage.getItem('doctorUserDetail'));
            userDetail.first_name = data.first_name;
            userDetail.last_name = data.last_name;
            userDetail.profile_image = data.profile_image;
            localStorage.setItem('doctorUserDetail', JSON.stringify(userDetail));
        },
        setProfileDetail(state, profile) {
            localStorage.setItem('doctorProfileDetail', JSON.stringify(profile))
            state.doctorProfileDetail = JSON.stringify(profile);
        },
        resetDoctorProfile(state) {
            localStorage.removeItem('doctorProfileDetail');
            state.doctorProfileDetail = null;
        },
        setRegistrationStepCount(state, count) {
            localStorage.setItem('registrationStepperCount', count);
            state.registrationStepperCount = count;
        },
        deleteRegistrationStepperCount(state) {
            localStorage.removeItem('registrationStepperCount');
            state.registrationStepperCount = 1;
        },
        setRegistrationDetail(state, data) {
            localStorage.setItem('registrationFieldDetail', JSON.stringify(data))
            state.registrationFieldDetail = JSON.stringify(data);
        },
        destroyRegistrationStepDetail(state) {
            localStorage.removeItem('registrationFieldDetail');
            state.registrationFieldDetail = 0;
        },
        setSubmitFlag(state, data) {
            localStorage.setItem('registrationSubmitFlag', data)
            state.registrationSubmitFlag = data;
        },
        setProfileImageFlag(state, data) {
            localStorage.setItem('displayProfileImage', data);
            state.displayProfileImage = data;
        },
        setDoctorSearchFilter(state, data) {
            localStorage.setItem('searchDoctorFilter', JSON.stringify(data))
            state.searchDoctorFilter = JSON.stringify(data);
        },
        destroyDoctorFilter(state) {
            localStorage.removeItem('searchDoctorFilter');
            state.searchDoctorFilter = {}
        },
        updatePatientProfileDetails(state, data) {
            let userDetail = JSON.parse(localStorage.getItem('userDetail'));
            userDetail.full_name = data.full_name;
            userDetail.profile_image = data.profile_image;
            userDetail.email = data.email;
            userDetail.phone_number = data.phone_number;
            userDetail.username = data.username;
            localStorage.setItem('userDetail', JSON.stringify(userDetail));
        },
        setModuleName(state, data) {
            localStorage.setItem('moduleName', data)
            state.moduleName = data;
        },
        // Set compare doctor in local storage
        storeCompareDoctor(state, data) {
            localStorage.setItem('compareDoctorDetail', JSON.stringify(data))
            state.compareDoctorDetail = JSON.stringify(data);
        },
        // remove compare doctor in local storage
        removeCompareDoctor(state) {
            localStorage.removeItem('compareDoctorDetail')
            state.compareDoctorDetail = null;
        },
        // Set multiple booking details in local storage
        storeMultipleBookingDetail(state, data) {
            localStorage.setItem('multipleBookingDetail', JSON.stringify(data))
            state.multipleBookingDetail = JSON.stringify(data);
        },
        // Set multiple booking doctor list in local storage
        storeMultipleBookingDoctorList(state, data) {
            localStorage.setItem('multipleBookingDoctorList', JSON.stringify(data))
            state.multipleBookingDoctorList = JSON.stringify(data);
        },
        // Remove multiple doctor list in local storage
        removeMultipleBookingDoctorList(state) {
            localStorage.removeItem('multipleBookingDoctorList')
            state.multipleBookingDoctorList = null;
        },

        // Remove multiple booking detail in local storage
        removeMultipleBookingDetail(state) {
            localStorage.removeItem('multipleBookingDetail')
            state.multipleBookingDetail = null;
        },

        // Set compare doctor Ids in local storage
        storeCompareDoctorIds(state, data) {
            localStorage.setItem('storeCompareDoctorIds', JSON.stringify(data))
            state.compareDoctorIds = JSON.stringify(data);
        },

        // Set notification in local storage
        storeNotification(state, data) {
            localStorage.setItem('unreadNotification', data)
            state.unreadNotification = data;
        },
        // Remove notification in local storage
        removeNotification(state) {
            localStorage.removeItem('unreadNotification')
            state.unreadNotification = null;
        },


        // Set doctor notification in local storage
        storeDoctorNotification(state, data) {
            localStorage.setItem('unreadDoctorNotification', data)
            state.unreadDoctorNotification = data;
        },
        // Remove doctor notification in local storage
        removeDoctorNotification(state) {
            localStorage.removeItem('unreadDoctorNotification')
            state.unreadDoctorNotification = null;
        },
    },
    actions: {
        doLogout({ commit }) {
            commit('logoutUser');
            router.push({ name: 'patientHome' }).catch(()=>{});
        },
        doLogin({ commit }, data) {
            commit('loginUser', data);
        },
        doDoctorLogout({ commit }) {
            commit('logoutDoctorUser');
            router.push({ name: 'doctorLogin' }).catch(()=>{});
        },
        saveDoctorProfile({ commit }, data) {
            commit('setProfileDetail', data);
        },
        deleteDoctorProfile({ commit }) {
            commit('resetDoctorProfile');
        },
        saveRegistrationStepCount({ commit }, data) {
            commit('setRegistrationStepCount', data);
        },
        destroyRegistrationStepper({ commit }) {
            commit('deleteRegistrationStepperCount');
        },
        saveRegistrationStepDetail({ commit }, data) {
            commit('setRegistrationDetail', data);
        },
        destroyRegistrationStepDetail({ commit }) {
            commit('destroyRegistrationStepDetail');
        },
        hideRegistrationProfileImage({ commit }, data) {
            commit('setProfileImageFlag', data);
        },
        saveRegistrationFlag({ commit }, data) {
            commit('setSubmitFlag', data);
        },
        saveDoctorSearchFilter({ commit }, data) {
            commit('setDoctorSearchFilter', data);
            router.push({ name: 'doctorListing' }).catch(()=>{});
        },
        destoryDoctorFilter({ commit }) {
            commit('destroyDoctorFilter');
        },
        storeCompareDoctor({ commit }, data) {
            commit('storeCompareDoctor', data);
        },
        removeCompareDetail({ commit }) {
            commit('removeCompareDoctor');
        },
        storeMultipleBookingDetail({ commit }, data) {
            commit('storeMultipleBookingDetail', data);
        },
        storeMultipleBookingDoctorList({ commit }, data) {
            commit('storeMultipleBookingDoctorList', data);
        },
        removeMultipleBookingDoctorList({ commit }, data) {
            commit('removeMultipleBookingDoctorList', data);
        },
        removeMultipleBookingDetail({ commit }, data) {
            commit('removeMultipleBookingDetail', data);
        },
        storeCompareDoctorIds({ commit }, data) {
            commit('storeCompareDoctorIds', data);
        },
        storeDoctorNotification({ commit }, data) {
            commit('storeDoctorNotification', data);
        },
        removeDoctorNotification({ commit }, data) {
            commit('removeDoctorNotification', data);
        },

        storeNotification({ commit }, data) {
            commit('storeNotification', data);
        },
        removeNotification({ commit }, data) {
            commit('removeNotification', data);
        },
    },

    modules:{
        loader
    }
})
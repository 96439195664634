import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import Vuelidate from 'vuelidate';
import axios from 'axios';
import constant from './constant';
import router from "./router";
import store from "./stores/store";
import interceptorsSetup from "./interceptors";
import VueToast from 'vue-toast-notification';
import VueCountdown from '@chenfengyuan/vue-countdown';
import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete';
import * as VueGoogleMaps from 'vue2-google-maps';
import VueChatScroll from 'vue-chat-scroll';
import VueClipboard from 'vue-clipboard2';

Vue.use(VueClipboard);
Vue.use(VueChatScroll);

Vue.use(VueToast, {
    // One of the options
    position: 'top',
    duration: 3000
});

Vue.use(VuetifyGoogleAutocomplete, {
    apiKey: process.env.VUE_APP_GOOGLE_MAP_KEY,
    vueGoogleMapsCompatibility: true,
    language: 'en',
});
Vue.use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_GOOGLE_MAP_KEY,
        libraries: 'places',
        componentRestrictions: { country: "us" },
        region: 'US'
    }
});
Vue.prototype.$http = axios
Vue.component(VueCountdown.name, VueCountdown);
Vue.config.productionTip = false
Vue.use(Vuelidate);
export const eventBus = new Vue();
import VueSocialauth from "vue-social-auth";
Vue.use(VueSocialauth, {
    providers: {
        facebook: {
            clientId: process.env.VUE_APP_FACEBOOK_CLIENT_ID,
            redirectUri: process.env.VUE_APP_FACEBOOK_REDIRECT_URL
        },
        google: {
            clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
            redirectUri: process.env.VUE_APP_GOOGLE_REDIRECT_URL
        },
        twitter: {
            clientId: process.env.VUE_APP_TWITTER_CLIENT_ID,
            url: process.env.VUE_APP_TWITTER_API_URL,
            redirectUri: process.env.VUE_APP_TWITTER_REDIRECT_URL,
            responseParams: {
                oauth_token: 'oauth_token',
            }
        }
    },
});


router.beforeEach((to, from, next) => {
    localStorage.setItem('module', to.meta.module);
    store.commit('setModuleName', to.meta.module);
    if (to.meta.module == 'doctor') {
        if (to.name == 'doctorLogin' && store.state.isDoctorLoggedIn) {
            next({ name: 'doctorHome' })
        }
    }
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (to.meta.module == 'patient' && !store.state.isLoggedIn) {
            next({ name: 'patientHome' })
        } else if (to.meta.module == 'doctor' && !store.state.isDoctorLoggedIn) {
            next({ name: 'doctorLogin' })
        } else {
            next() // go to wherever I'm going
        }
    } else {
        next() // does not require auth, make sure to always call next()!
    }
})

Vue.filter('uppercase', function (value) {
    if (!value) return '';
    return value.toUpperCase();
})
Vue.filter('firstLetterCapital', (value) => {
    if (value) {
        value = value.toLowerCase();
        return value.charAt(0).toUpperCase() + value.slice(1);
    }
})

Vue.filter('titleCaseConverter', (value) => {
    if (value) {
        value = value.replaceAll("_", " ");
        return value.replace(/(?:^|\s|-)\S/g, x => x.toUpperCase());
    }
});

Vue.filter('string_limit', function (value, size) {
    if (!value) return '';
    value = value.toString();

    if (value.length <= size) {
        return value;
    }
    return value.substr(0, size) + '...';
});

Vue.prototype.goToPreviousLocation = function () {
    this.$router.go(-1);
}

import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

window.Pusher = Pusher;

const user = JSON.parse(localStorage.getItem('userDetail'));
const doctor = JSON.parse(localStorage.getItem('doctorUserDetail'));

if (user || doctor) {

    let token = '';

    if (user) {
        token = user.token;
    } else if (doctor) {
        token = doctor.token;
    } else {
        token = '';
    }

    window.Echo = new Echo({
        broadcaster: 'pusher',
        key: process.env.VUE_APP_WEBSOCKETS_KEY,
        wsHost: process.env.VUE_APP_WEBSOCKETS_SERVER,
        cluster: process.env.VUE_APP_WEBSOCKETS_CLUSTER,
        // "options" used as a second parameter in authorizer
        authorizer: (channel) => {
            return {
                authorize: (socketId, callback) => {
                    axios({
                        method: "POST",
                        url: process.env.VUE_APP_WEBSOCKETS_AUTH_URL,
                        data: {
                            socket_id: socketId,
                            channel_name: channel.name,
                        },
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }

                    })
                        .then((response) => {
                            callback(false, response.data);
                        })
                        .catch((error) => {
                            callback(true, error);
                        });
                },
            };
        },
    });
}

// call vue axios interceptors
interceptorsSetup();
new Vue({
    router,
    vuetify,
    constant,
    store,
    render: h => h(App)
}).$mount('#app')
<template>
   
      <!-- Loader -->
      <div class="loader" v-if="isApiLoading">
        <v-progress-circular
          :size="70"
          :width="7"
          color="#10DF34"
          indeterminate
        ></v-progress-circular>
       
      </div>
  
  </template>
  
  <script>
//   import Vue from 'vue';
  import {  mapGetters } from 'vuex';
  export default {
   
    computed:{
    ...mapGetters('loader', ['isApiLoading'])
    
    }
  };
  </script>
  
  <style scoped>
  /* Your CSS styles for the loader */
  .loader {
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,0.6);
        
    /* Patient color
    color:"#10DF34"  */
    /* Doctor color
     color="#fbe30a" */
}
  </style>
  
export default Object.freeze({
  DOCTOR_ROLE_ID: 2,
  PATIENT_ROLE_ID: 3,
  YOUTUBE_VIDEO_FORMAT: 'mp4',
  DOCTOR_LISTING_PER_PAGE: 10,
  DEFAULT_SPECIALITY: 311,
  DEFAULT_LOCATION: "New York",
  DEFAULT_COUNTRY_CODE: "+1",
  DEFAULT_DATE_FORMAT: 'MM-DD-YYYY',
  FEED_PER_PAGE: 10,
  CONTENT_COUNT_DISPLAY: 500,
  DEFAULT_APPOINTMENT_DURATION: "00:15",
  CALENDER_BLOCK_SLOT_COLOR: "#F5F5F5",
  CALENDER_INPERSON_COLOR: "#ff1e970f",
  CALENDER_VIDEOCONSULTANCY_COLOR: "#F4EDFB",
  DOCTOR_APPOINTMENT_COMPLETED_PER_PAGE: 10,
  DOCTOR_CANCELLED_APPOINTMENT_PER_PAGE: 10,
  DOCTOR_RESCHDULE_APPOINTMENT_PER_PAGE: 10,
  DOCTOR_WAIT_LIST_APPOINTMENT_PER_PAGE: 10,
  DOCTOR_OFFLINE_APPOINTMENT_PER_PAGE: 10,
  DOCTOR_NEW_APPOINTMENT_PER_PAGE: 10,
  DOCTOR_UPCOMING_APPOINTMENT_PER_PAGE: 10,
  DOCTOR_TODAY_APPOINTMENT_PER_PAGE: 10,
  DOCTOR_APPOINTMENT_COUNTDOWN_MINUTE: 5,
  DOCTOR_REVIEWS_PER_PAGE: 10,
  SORT_BY_REVIEW_ITEMS: [
    {
      text: "Most Relevant",
      id: "most-relevant"
    },
    {
      text: "New Added",
      id: "new-added"
    },
    {
      text: "Old Review",
      id: "old-review"
    }
  ],
  DEFAULT_SORT_BY_REVIEW_ITEM: "most-relevant",
  PATIENT_SIDE_DOCTOR_DETAIL_REVIEWS_PER_PAGE: 2,

  PRIMARY_CARE: "Primary Care Physician",
  DENTIST: "Dentist",
  DERMATOLOGIST: "Dermatologist",
  OB_GYN: "OB-GYN",
  PSYCHIATRIST: "Psychiatrist",
  PLASTIC_SURGEON: "Plastic Surgeon",
  ABOUT: "About YUME",
  DATA_AND_PRIVACY: "Data & Privacy",
  APPOINTMENTS: "Appointments",
  ACCOUNT: "Account",
  REVIEW: "Review",
  DEFAULT_LATTITUDE: "40.76495344313075",
  DEFAULT_LONGITUDE: "-73.97511054665219",
  PATIENT_SIDE_DOCTOR_DETAIL_FEEDS_PER_PAGE: 2,
  MOST_RECENT_MESSAGE_COUNT: 20
});

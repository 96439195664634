import Vue from 'vue';
import Router from 'vue-router';

const PatientHome = import('@/views/patient/Home');
const Patient = import('@/views/patient/Patient');
const Doctor = import('@/views/doctor/Doctor');
// const DoctorHome = import('@/views/doctor/Home');
const FindDoctorProfile = import('@/views/doctor/FindDoctorProfile');
const DoctorProfileListing = import('@/views/doctor/ProfileListing');
const RegistrationStep = import('@/views/doctor/Registration');
const AccountExists = import('@/views/doctor/AccountExists');
const DoctorLogin = import('@/views/doctor/DoctorLogin');
const DoctorProfile = import('@/views/doctor/DoctorProfileInformation');
const DoctorSettings = import('@/views/doctor/DoctorSettings');
const DoctorProfileOverview = import('@/views/doctor/DoctorProfileOverview');
const DoctorProfileLocation = import('@/views/doctor/DoctorProfileLocation');
const DoctorPhotosVideos = import('@/views/doctor/DoctorPhotosVideos');
const DoctorReview = import('@/views/doctor/DoctorReview');
const DoctorUploadDocument = import('@/views/doctor/DoctorUploadDocument');
const DoctorListing = import('@/views/patient/DoctorListing');
const PatientProfile = import('@/views/patient/PatientProfile');
const PatientSettings = import('@/views/patient/PatientSettings');
const PatientCancelAccount = import('@/views/patient/PatientCancelAccount');
const PatientContactUs = import('@/views/patient/PatientContactUs');
const DoctorDetail = import('@/views/patient/DoctorDetail');
const PublicDoctorDetail = import('@/views/patient/PublicDoctorDetail');
const PatientFavorites = import('@/views/patient/PatientFavorites');
const CompareDoctor = import('@/views/patient/CompareDoctor');
const PatientFeed = import('@/views/patient/PatientFeed');
const PatientFeedComment = import('@/views/patient/PatientFeedComment');
const Feed = import('@/views/doctor/Feed');
const DoctorFeed = import('@/views/doctor/DoctorFeed');
const DoctorFeedComment = import('@/views/doctor/DoctorFeedComment');
const DoctorCalender = import('@/views/doctor/DoctorCalender');
const DoctorAppointmentCompleted = import('@/views/doctor/DoctorAppointmentCompleted');
const DoctorAppointmentCancelled = import('@/views/doctor/DoctorAppointmentCancelled');
const DoctorAppointmentRescheduleRequest = import('./views/doctor/DoctorAppointmentRescheduleRequest');
const DoctorAppointmentWaitList = import('@/views/doctor/DoctorAppointmentWaitList');
const DoctorAppointment = import('@/views/doctor/DoctorAppointment');
const DoctorAppointmentUpcoming = import('@/views/doctor/DoctorAppointmentUpcoming');
const DoctorAppointmentToday = import('@/views/doctor/DoctorAppointmentToday');
const DoctorAppointmentOffline = import('@/views/doctor/DoctorAppointmentOffline');
const DoctorDashboard = import('@/views/doctor/DoctorDashboard');
const DoctorMessages = import('@/views/doctor/DoctorMessages');
// const MyBookings = import('@/views/patient/MyBookings');
// const MyPastBookings = import('@/views/patient/MyPastBookings');
const DoctorBooking = import('@/views/patient/DoctorBooking');
const TermsConditions = import('@/views/patient/TermsConditions');
const PrivacyPolicy = import('@/views/patient/PrivacyPolicy');
const AboutUs = import('@/views/patient/AboutUs');
const FrequentlyAskedQuestions = import('@/views/patient/FrequentlyAskedQuestions');
const CustomerService = import('@/views/patient/CustomerService');
const FrequentlyAskedQuestionsListing = import('@/views/patient/FrequentlyAskedQuestionsListing');
const InviteDoctor = import('@/views/doctor/InviteDoctor');
const ReferralNetwork = import('@/views/doctor/ReferralNetwork');
const DoctorAboutUs = import('@/views/doctor/DoctorAboutUs');

Vue.use(Router);

const isDoctorLoggedIn = localStorage.getItem('isDoctorLoggedIn');

const patientRoutes = [{
    path: '*',
    redirect: isDoctorLoggedIn ? 'doctor/home' : 'patient/home'
},
{
    path: '/auth/:provider/callback',
    component: {
        template: '<div class="auth-component"></div>'
    }
},
{
    path: '/patient',
    component: () => Patient,

    children: [{
        path: '',
        redirect: 'patient/home',
        meta: {
            requiresAuth: false,
            module: 'patient'
        }
    },
    {
        path: 'home',
        name: 'patientHome',
        component: () => PatientHome,
        meta: {
            requiresAuth: false,
            module: 'patient'
        }
    },
    {
        path: 'doctor-list',
        name: 'doctorListing',
        component: () => DoctorListing,
        meta: {
            requiresAuth: false,
            module: 'patient'
        }
    },
    {
        path: 'profile',
        name: 'patientProfile',
        component: () => PatientProfile,
        meta: {
            requiresAuth: true,
            module: 'patient'
        }
    },
    {
        path: 'settings',
        name: 'patientSettings',
        component: () => PatientSettings,
        meta: {
            requiresAuth: true,
            module: 'patient'
        }
    },
    {
        path: 'cancel-account',
        name: 'patientCancelAccount',
        component: () => PatientCancelAccount,
        meta: {
            requiresAuth: true,
            module: 'patient'
        }
    },
    {
        path: 'contact-us',
        name: 'patientContactUs',
        component: () => PatientContactUs,
        meta: {
            requiresAuth: true,
            module: 'patient'
        }
    },
    {
        path: 'doctor-profile/:id/:locationId?',
        name: 'doctorPublicProfile',
        component: () => DoctorDetail,
        meta: {
            requiresAuth: false,
            module: 'patient'
        }
    },
    {
        path: 'doctor-public-profile/:id',
        name: 'doctorNonRegPublicProfile',
        component: () => PublicDoctorDetail,
        meta: {
            requiresAuth: false,
            module: 'patient'
        }
    },
    {
        path: 'doctor-booking',
        name: 'DoctorBooking',
        component: () => DoctorBooking,
        meta: {
            requiresAuth: false
        }
    },
    // {
    //     path: 'my-upcoming-booking',
    //     name: 'myUpComingBookings',
    //     component: () => MyBookings,
    //     meta: {
    //         requiresAuth: true,
    //         module: 'patient'
    //     }
    // }, {
    //     path: 'my-past-booking',
    //     name: 'myPastBookings',
    //     component: () => MyBookings,
    //     meta: {
    //         requiresAuth: true,
    //         module: 'patient'
    //     }
    // },
    // {
    //     path: 'my-offline-booking',
    //     name: 'offlineBookings',
    //     component: () => MyBookings,
    //     meta: {
    //         requiresAuth: true,
    //         module: 'patient'
    //     }
    // },
    {
        path: 'favorites',
        name: 'patientFavorites',
        component: () => PatientFavorites,
        meta: {
            requiresAuth: true,
            module: 'patient'
        }
    },
    {
        path: 'compare-doctor',
        name: 'compareDoctor',
        component: () => CompareDoctor,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: 'feed',
        name: 'patientFeed',
        component: () => PatientFeed,
        meta: {
            requiresAuth: false,
            module: 'patient'
        }
    },
    {
        path: 'feed-comment/:id',
        name: 'patientFeedComment',
        component: () => PatientFeedComment,
        meta: {
            requiresAuth: false,
            module: 'patient'
        }
    },
    {
        path: 'terms-condition',
        name: 'termsConditions',
        component: () => TermsConditions,
        meta: {
            requiresAuth: false,
            module: 'patient'
        }
    },
    {
        path: 'privacy-policy',
        name: 'privacyPolicy',
        component: () => PrivacyPolicy,
        meta: {
            requiresAuth: false,
            module: 'patient'
        }
    },
    {
        path: 'about-us',
        name: 'aboutUs',
        component: () => AboutUs,
        meta: {
            requiresAuth: false,
            module: 'patient'
        }
    },
    {
        path: 'faq',
        name: 'frequentlyAskedQuestions',
        component: () => FrequentlyAskedQuestionsListing,
        meta: {
            requiresAuth: false,
            module: 'patient'
        }
    },
    {
        path: 'faq/:category',
        name: 'frequentlyAskedQuestionsListing',
        component: () => FrequentlyAskedQuestions,
        meta: {
            requiresAuth: false,
            module: 'patient'
        }
    },
    {
        path: 'customer-service',
        name: 'customerService',
        component: () => CustomerService,
        meta: {
            requiresAuth: false,
            module: 'patient'
        }
    },
    ],
}
];
const doctorRoutes = [{
    path: '/doctor',
    component: () => Doctor,

    children: [{
        path: '',
        redirect: 'doctor/home',
        meta: {
            requiresAuth: true,
            module: 'doctor'
        }
    },
    {
        path: 'login',
        name: 'doctorLogin',
        component: () => DoctorLogin,
        meta: {
            requiresAuth: false,
            module: 'doctor'
        }
    },
    {
        path: 'home',
        name: 'doctorHome',
        component: () => DoctorDashboard,
        meta: {
            requiresAuth: true,
            module: 'doctor'
        }
    },
    {
        path: 'edit-profile',
        name: 'doctorProfile',
        component: () => DoctorProfile,
        meta: {
            requiresAuth: true,
            module: 'doctor'
        }
    },
    {
        path: 'upload-document',
        name: 'doctorUploadDocument',
        component: () => DoctorUploadDocument,
        meta: {
            requiresAuth: true,
            module: 'doctor'
        }
    },
    {
        path: 'reviews',
        name: 'doctorReview',
        component: () => DoctorReview,
        meta: {
            requiresAuth: true,
            module: 'doctor'
        }
    },
    {
        path: 'settings',
        name: 'DoctorSettings',
        component: () => DoctorSettings,
        meta: {
            requiresAuth: true,
            module: 'doctor'
        }
    },
    {
        path: 'upload-media',
        name: 'DoctorPhotosVideos',
        component: () => DoctorPhotosVideos,
        meta: {
            requiresAuth: true,
            module: 'doctor'
        }
    },
    {
        path: 'search',
        name: 'findDoctorProfile',
        component: () => FindDoctorProfile,
        meta: {
            requiresAuth: false,
            module: 'doctor'
        }
    },
    {
        path: 'search/:firstName/:lastName*',
        name: 'ProfileListing',
        component: () => DoctorProfileListing,
        meta: {
            requiresAuth: false,
            module: 'doctor'
        }
    },
    {
        path: 'registration/:firstName?/:lastName?/:npiNumber?',
        name: 'doctorRegistration',
        component: () => RegistrationStep,
        meta: {
            requiresAuth: false,
            module: 'doctor'
        }
    },
    {
        path: 'account-exists',
        name: 'AccountExists',
        component: () => AccountExists,
        meta: {
            requiresAuth: false,
            module: 'doctor'
        }
    },
    {
        path: 'profile-overview',
        name: 'doctorProfileOverview',
        component: () => DoctorProfileOverview,
        meta: {
            requiresAuth: true,
            module: 'doctor'
        }
    },
    {
        path: 'profile/:id',
        name: 'doctorDetail',
        component: () => DoctorDetail,
        meta: {
            requiresAuth: true,
            module: 'doctor'
        }
    },
    {
        path: 'calender',
        name: 'doctorCalender',
        component: () => DoctorCalender,
        meta: {
            requiresAuth: true,
            module: 'doctor'
        }
    },
    {
        path: 'appointments-completed',
        name: 'doctorAppointmentCompleted',
        component: () => DoctorAppointmentCompleted,
        meta: {
            requiresAuth: true,
            module: 'doctor'
        }
    },
    {
        path: 'appointments-cancelled',
        name: 'doctorAppointmentCancelled',
        component: () => DoctorAppointmentCancelled,
        meta: {
            requiresAuth: true,
            module: 'doctor'
        }
    },
    {
        path: 'appointments-reschedule-request',
        name: 'doctorAppointmentRescheduleRequest',
        component: () => DoctorAppointmentRescheduleRequest,
        meta: {
            requiresAuth: true,
            module: 'doctor'
        }
    },
    {
        path: 'appointments-wait-list',
        name: 'doctorAppointmentWaitList',
        component: () => DoctorAppointmentWaitList,
        meta: {
            requiresAuth: true,
            module: 'doctor'
        }
    },
    {
        path: 'appointments-offline',
        name: 'doctorAppointmentOffline',
        component: () => DoctorAppointmentOffline,
        meta: {
            requiresAuth: true,
            module: 'doctor'
        }
    },
    {
        path: 'appointments',
        name: 'doctorAppointment',
        component: () => DoctorAppointment,
        meta: {
            requiresAuth: true,
            module: 'doctor'
        }
    },
    {
        path: 'appointments-upcoming',
        name: 'doctorAppointmentUpcoming',
        component: () => DoctorAppointmentUpcoming,
        meta: {
            requiresAuth: true,
            module: 'doctor'
        }
    },
    {
        path: 'appointments-today',
        name: 'doctorAppointmentToday',
        component: () => DoctorAppointmentToday,
        meta: {
            requiresAuth: true,
            module: 'doctor'
        }
    },
    {
        path: 'dashboard',
        name: 'doctorDashboard',
        component: () => DoctorDashboard,
        meta: {
            requiresAuth: true,
            module: 'doctor'
        }
    },
    {
        path: 'all-feed',
        name: 'feed',
        component: () => Feed,
        meta: {
            requiresAuth: true,
            module: 'doctor'
        }
    },
    {
        path: 'feed',
        name: 'doctorFeed',
        component: () => DoctorFeed,
        meta: {
            requiresAuth: true,
            module: 'doctor'
        }
    },
    {
        path: 'feed-comment/:id',
        name: 'doctorFeedComment',
        component: () => DoctorFeedComment,
        meta: {
            requiresAuth: false,
            module: 'doctor'
        }

    },
    {
        path: 'messages/:id?',
        name: 'doctorMessages',
        component: () => DoctorMessages,
        meta: {
            requiresAuth: true,
            module: 'doctor'
        }
    },
    // Do not remove below route
    {
        path: 'profile-location',
        name: 'doctorProfileLocation',
        component: () => DoctorProfileLocation,
        meta: {
            requiresAuth: true,
            module: 'doctor'
        }
    },
    {
        path: 'invite-doctor',
        name: 'inviteDoctor',
        component: () => InviteDoctor,
        meta: {
            requiresAuth: true,
            module: 'doctor'
        }
    },
    {
        path: 'referral-network',
        name: 'referralNetwork',
        component: () => ReferralNetwork,
        meta: {
            requiresAuth: true,
            module: 'doctor'
        }
    },
    {
        path: 'doctor-about-us',
        name: 'doctor-about-us',
        component: () => DoctorAboutUs,
        meta: {
            requiresAuth: true,
            module: 'doctor'
        }
    }
    ],
}];
let routes = [...patientRoutes, ...doctorRoutes];
export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    // to, from, savedPosition these are used in scroll behavior as a parameters
    scrollBehavior() {
        return { x: 0, y: 0 }
    }
})
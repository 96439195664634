<template>
<v-app id="app" class="yume-pages" :class="{'patient-page': moduleName === 'patient', 'doctor-page': moduleName === 'doctor'}">
    <api-loader/>
    <router-view />
</v-app>
</template>
<script>
import ApiLoader from "@/components/common/ApiLoader.vue"
import {
    mapState
} from "vuex";
export default {
    components:{
        ApiLoader
    },
    name: "App",
    computed: {
        ...mapState(["moduleName"]),
    },

};


import("./assets/scss/style.scss");
import("vue-toast-notification/dist/theme-sugar.css");
</script>
// myModule.js

  const state = {
    loading: false
  };
  
  const mutations = {
    SET_API_LOADING(state, value) {
      state.loading = value;
  },
  };
  
  const actions = {
    showLoader({commit}, value){
    commit("SET_API_LOADING", value)
}  
};
  
  const getters = {
   isApiLoading(state){
    return state.loading
   }
  };
  
  export default {
    namespaced: true, // Add this line to make it a namespaced module
    state,
    mutations,
    actions,
    getters
  };
  
import axios from 'axios';

export default function setup() {
    // Add a request interceptor

    axios.interceptors.request.use(request => {
        let domainModule = localStorage.getItem('module');
        let language = localStorage.getItem("language") ? localStorage.getItem("language") : "en";
        const isApiUrl = request.url.startsWith(process.env.VUE_APP_API_ENDPOINT);
        if (domainModule === 'doctor') {
            const isDoctorLoggedIn = localStorage.getItem('isDoctorLoggedIn');
            const doctor = JSON.parse(localStorage.getItem('doctorUserDetail'));
            const doctorToken = doctor ? doctor.token : '';
            if (isDoctorLoggedIn && isApiUrl) {
                request.headers.Authorization = `Bearer ${doctorToken}`;
            }
            request.headers['X-localization'] = language;
        } else {
            const isLoggedIn = localStorage.getItem('isLoggedIn');
            const user = JSON.parse(localStorage.getItem('userDetail'));
            const token = user ? user.token : '';
            if (isLoggedIn && isApiUrl) {
                request.headers.Authorization = `Bearer ${token}`;
            }
            request.headers['X-localization'] = language;
        }


        return request;
    })

    // Add a response interceptor
    axios.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
        
        return Promise.reject(error);
    });
}
